(function($){

    var methods = {
        destroy : function() {
            this.each(function() {
                var slideshow = $(this);
                var settings = slideshow.data('_jq_slideshow_settings');

                if (slideshow.cycle) {
                    slideshow.cycle('destroy');

                    if (slideshow.parent().hasClass(settings.wrapperClass)) {
                        slideshow.parent().find("." + settings.controlsClass).remove();
                        slideshow.parent().off();
                        slideshow.unwrap();
                    }
                }

            });
        },
        stop: function() {
            this.each(function() {
                var slideshow = $(this);
                slideshow.cycle('stop');
            });
        },
        start: function() {
            this.each(function() {
                var slideshow = $(this);
                slideshow.cycle('start');
            });
        },
        init: function(settings) {
            var settings = $.extend({
                slides: '> .slideshow-item',
                speed: 600,
                manualSpeed: 300,
                timeout: 10000,
                pauseOnHover: true,
                pagerTemplate: '<a href="#{{slideNum}}"><span>{{slideNum}}</span></a>',
                wrapperClass: 'slideshow-wrapper',
                controlsClass: 'slideshow-controls',
                controlsPrevClass: 'slideshow-prev',
                controlsNextClass: 'slideshow-next',
                controlsPagerClass: 'slideshow-pager',
                controlsPrevText: 'Previous',
                controlsNextText: 'Next'
            }, settings);


            this.each(function() {
                var slideshow = $(this);

                $.each(slideshow.data(), function(index,value) {
                    index = index.replace(/^cycle([A-Z])/g, function($0, $1) { return $1.toLowerCase(); });
                    settings[index] = value;
                });

                slideshow.data('_jq_slideshow_settings', settings);

                var wrapper = slideshow
                        .wrap('<div class="'+settings.wrapperClass+'"></div>')
                        .parent();

                wrapper
                    .append(
                        '<div class="'+settings.controlsClass+'">' +
                            '<a class="'+settings.controlsPrevClass+'" href="#"><span>'+settings.controlsPrevText+'</span></a>' +
                            '<a class="'+settings.controlsNextClass+'" href="#"><span>'+settings.controlsNextText+'</span></a>' +
                            '<div class="'+settings.controlsPagerClass+'"></div>' +
                        '</div>'
                    );

                settings.pager =  wrapper.find("."+settings.controlsPagerClass);
                settings.prev =  wrapper.find("."+settings.controlsPrevClass);
                settings.next =  wrapper.find("."+settings.controlsNextClass);

                if ($.event.special.swipe) {
                    settings.swipe = true;
                }

                slideshow.cycle(settings);

                if (settings.fullImageLink === true) {
                    wrapper.find('.'+settings.controlsClass).on('click', function(e) {
                        var opts = slideshow.data('cycle.opts'),
                            currentSlide = opts.slides[opts.currSlide];
                        if (e.target == this && $(currentSlide).find('a:first').size() == 1) {
                            document.location.href = $(currentSlide).find('a:first').attr('href');
                        }
                    });

                    slideshow.on('load cycle-update-view', function(e, opts) {
                        var currentSlide = opts.slides[opts.currSlide],
                            slideshowControls = wrapper.find('.'+settings.controlsClass);

                        slideshowControls.removeClass('slideshow-has-link');
                        if ($(currentSlide).find('a:first').size() == 1) {
                            slideshowControls.addClass('slideshow-has-link');
                        }
                    });
                }

                slideshow.on('cycle-update-view', function(e, opts) {
                    var slideshowControls = wrapper.find('.'+settings.controlsClass);

                    if (opts.slideCount <= 1) {
                        slideshowControls.hide();
                    }
                });
            });

            return this;
        }
    };

    $.fn.slideshow = function(methodOrOptions) {

        if (methods[methodOrOptions]) {
            return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        }

        return methods.init.apply( this, arguments );
    };


    $(function() {
        $(".slideshow").slideshow();
    });
})(jQuery);

