//=require ../../webroot/components/jquery/dist/jquery.js
//=require ../../webroot/components/jquery-cycle2/build/jquery.cycle2.js
//=require ../../webroot/components/jquery-cycle2/build/plugin/jquery.cycle2.carousel.min.js
//=require ../../webroot/components/jquery-cycle2-slideshow/src/slideshow.js
//=require ../../webroot/components/jquery-zoom/jquery.zoom.js
//=require ../../webroot/components/fancybox/source/jquery.fancybox.js
//=require ../../webroot/components/jquery.meanmenu/jquery.meanmenu.min.js

//= require ../../Dirmacom/DirmacomShop/webroot/js/jquery-ui-1.10.3.custom.min.js
//= require ../../Dirmacom/DirmacomShop/webroot/js/facets.js

//= require messages.js

$(function($) {

  $(".nav-main").meanmenu({
    meanScreenWidth: 768
  });

  $("#facet-toggle").click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass("open");
  });

  $(".product-assets").each(function() {
    var assets = $(this);
    var zoomArea = $(this).find(".image-large");

    assets.find(".images > .image").on('click zoom.activate', function() {
      zoomArea.addClass('loading');
      var image = $(this).find("img");
      var zoomImage = $("<img>")
        .attr('src', image.data('normal'))
        .on('load', function(e) {
          assets.find(".image-large").trigger('zoom.destroy');

          zoomArea.empty().append(zoomImage);
          zoomArea.zoom({
            url: image.data('zoom')
          });

          zoomArea.removeClass('loading');
        });

    }).eq(0).trigger('zoom.activate');
  });

  $(".fancybox").fancybox();

  $(".searchbtn").on('click', function(e) {
    e.preventDefault();
    var searchBox = $(".searchbox");

    searchBox.toggleClass('open');

    if (searchBox.hasClass('open')) {
      searchBox.find("input[type=text]").focus();
    }

  });

  $(".searchbox input[type=text]").on('blur', function(e) {
    e.preventDefault();

    setTimeout(function() {
      $(".searchbox").removeClass('open');
    }, 100);
  });

});
