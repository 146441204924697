// tooltips
$(document).ready(function() {
	$('div.facet .tooltip').tooltip({
		position: {
			at: 'top right',
			my: "left+40 top-15"
		}
	});
});

// facet more/less
$(document).ready(function() {

	$('div.facet').each(function() {

		var element = $(this),
			identifier = element.attr('id'),
			toggleDuration = 100,
			hiddenElements = $('li.hidden', element),
			more = $('a.more-info', this),
			more_text_less = more.data('moreLess'),
			more_text_more = more.data('textLess');

		var toggleMenu = function() {
			var collapsed = element.hasClass('collapsed');

			if (!collapsed) {
				hiddenElements.show(toggleDuration);
				more
                    .text(more_text_more)
                    .addClass('active');
			} else {
				hiddenElements.hide(toggleDuration);
				more
                    .text(more_text_less)
                    .removeClass('active');
			}
		}

		toggleMenu();
		more.on('click', function(e) {
			e.preventDefault();

			var state;
			var collapsed = element.hasClass('collapsed');
			if (collapsed) {
				element.removeClass('collapsed');
				var state = true;
			} else {
				element.addClass('collapsed');
				var state = false;
			}

			toggleMenu();

			$.post('/dirmacom_shop/facets/store_state.json', {
				'id': identifier,
				'state': !state
			});
		});
	});

});

// facet range slider
$(document).ready(function() {

	$('.range-slider').each(function() {

		var element = $(this),
				parent = element.parent('div'),
				indicator_from = $('.indicators .from', parent),
				indicator_to = $('.indicators .to', parent),
				current_from = parseInt(element.data('currentFrom'), 10),
				current_to = parseInt(element.data('currentTo'), 10),
				url = element.data('url');

		element.slider({
			'range': true,
			'min': parseInt(element.data('from'), 10),
			'max': parseInt(element.data('to'), 10),
			'values': [ current_from, current_to ],
			'stop': function(event, ui) {
				var dummyel = $('<a />').attr('href', url);
				var price_part = 'price:'+ ui.values.join('-');
				var full_url = url + '/price:'+ ui.values.join('-');

				if (dummyel[0].origin) {
					full_url = dummyel[0].origin + dummyel[0].pathname +'/'+ price_part + dummyel[0].search;
				}

				document.location.href = full_url;
			},
			'slide': function(event, ui) {
				indicator_from.text('€'+ ui.values[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."));
				indicator_to.text('€'+ ui.values[1].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."));
			}
		});

	});

	$('input.facet-attribute').on('click', function() {
		document.location.href = $('a', $(this).parent('li')).attr('href');
	});

});

