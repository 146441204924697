var Message = {

  init: function(el, opts) {
    var options = $.extend({
      type: 'info',
      autoCloseTimeout: 10000
    }, opts);

    el.addClass(options.type);

    var closeBtn = $("<a class='message-close'>&times;</a>");
    closeBtn.on('click', function() {
      Message.close(el);
    }).appendTo(el);

    if (options.autoCloseTimeout !== false) {
      window.setTimeout(function() {
        Message.close(el);
      }, options.autoCloseTimeout);
    }

    Message.open(el);
  },

  open: function(el) {
    el.delay(500).slideDown("fast");
  },

  close: function(el) {
    el.delay(500).slideUp("slow");
  }
};

$(function() {
  Message.init($("#flashMessage"));
  Message.init($("#noticeMessage"), { type: 'warning', autoCloseTimeout: false });
  Message.init($("#authMessage"), { type: 'error', autoCloseTimeout: false });
});
